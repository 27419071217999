@import "../shared/Variables.scss";


@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translateY(11px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes bgColourChange {
	0% {
		background-color: $colour-1;
	}

	100% {
		background-color: $colour-2;
	}
}

@keyframes colourChange {
	0% {
		color: $colour-2;
	}

	100% {
		color: $colour-3;
	}
}

@keyframes shake {
	0% {
		transform: rotate(-8deg);
	}

	100% {
		transform: rotate(8deg);
	}
}

body {
	background-color: $colour-1;
}

.landing-page__outer {
	width: 100vw;
	height: 100vh;
	color: $colour-2;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.landing-page__inner {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	width: 90%;
	margin: auto;
	height: 80vh;
	max-height: 1000px;

	h1 {
		font-weight: 500;
		font-size: 30px;
		margin: 0;
		animation: fadeUp 400ms ease-in forwards;
		animation-delay: 600ms;
		opacity: 0;
	}
	
	.logo {
		font-weight: 600;
		font-size: 50px;
		animation: fadeUp 400ms ease-in forwards;
		animation-delay: 300ms;
		opacity: 0;
		margin: 0;
		line-height: 1;
	}
	
	.social-icons {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: fit-content;
		margin: 0 auto;

		&:hover {
			a {
				svg {
					opacity: 0.4 !important;
				}
			}
		}

		a {
			cursor: pointer;
			margin-right: 30px;
			transition: 200ms ease;
			color: inherit;
			animation: fadeUp 300ms ease-in forwards;
			opacity: 0;
			transition: 200ms ease-in-out;

			&:nth-child(1) {
				animation-delay: 1s;
			}
			&:nth-child(2) {
				animation-delay: 1.1s;
			}
			&:last-of-type {
				margin-right: 0;
				animation-delay: 1.2s;
			}

			svg {
				transition-duration: 200ms;
			}

			&:hover {
				svg {
					opacity: 1 !important;
					animation: shake 500ms ease-in-out alternate infinite;
				}
			}
		}	
	}
}